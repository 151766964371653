import type { EmptyType } from '~/types'

export function range(start: number, end: number, step: number): number[] {
  if (step === 0) {
    throw new Error('Шаг не может быть равен нулю.')
  }

  const result: number[] = []
  const precision = step.toString().split('.')[1]?.length || 0
  const isAscending = step > 0

  for (
    let i = start;
    isAscending ? i <= end : i >= end;
    i = +Number(i + step).toFixed(precision)
  ) {
    result.push(i)
  }

  return result
}

export function declination(number: number, words: string): string {
  const w = words.split('|') as [string, string, string, string]
  const n = Math.abs(number)

  if (w.length !== 4) {
    throw new Error(
      "Параметр 'words' должен содержать ровно четыре части, разделенные символом '|'.",
    )
  }

  if (n % 10 === 1 && n % 100 !== 11) {
    return w[0] + w[1]
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return w[0] + w[2]
  } else {
    return w[0] + w[3]
  }
}

export function isEmpty(value: unknown): value is EmptyType {
  if (value === undefined || value === null) {
    return true
  }

  if (typeof value === 'boolean') {
    return !value
  }

  if (typeof value === 'string') {
    return value.trim() === ''
  }

  if (typeof value === 'number') {
    return value === 0
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  return false
}

export function isNotEmpty<T>(value: T): value is Exclude<T, EmptyType> {
  return !isEmpty(value)
}

export function numberFormat(value: string | number): string {
  const number = Number(Number(value).toFixed(2))

  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: Number.isInteger(number) ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(number)
}

export function upperFirst(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
