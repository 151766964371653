import type { SwiperContainer } from 'swiper/element'
import { FreeMode, Navigation } from 'swiper/modules'
import { register } from 'swiper/element'

export function useSwiper(
  swiperContainerRef: Ref<SwiperContainer | null>,
  options?: SwiperContainer['swiper']['params'],
) {
  onMounted(() => register())

  const swiper = computed(() => swiperContainerRef.value?.swiper || null)
  const swiperInitialized = ref(false)

  function initialize() {
    if (swiperContainerRef.value && options !== undefined) {
      Object.assign(swiperContainerRef.value, {
        modules: [Navigation, FreeMode],
        ...options,
      })
      swiperContainerRef.value.initialize()
      swiperInitialized.value = true
    }
  }

  watch(
    swiperContainerRef,
    (swiperContainerRef) => {
      if (swiperContainerRef) {
        nextTick(() => initialize())
      }
    },
    { immediate: true },
  )

  return {
    swiper,
    swiperInitialized,
  }
}
