export function useGifts() {
  const route = useRoute()
  const cartStore = useCartStore()
  const orderStore = useOrderStore()
  const device = useDevice()

  const { giftModal } = storeToRefs(cartStore)

  const showGiftLine = computed(() => {
    return (
      cartStore.giftList &&
      !orderStore.addressOverlay &&
      (route.name === 'home' ||
        route.path.includes('catalog') ||
        (route.name === 'product-id' && device.isDesktop))
    )
  })

  const giftList = computed(() => cartStore.giftList)
  const nextLevel = computed(() =>
    giftList.value?.levels.find((level) => level.remaining > 0),
  )
  const currentLevelNumber = computed(() => cartStore.giftList?.currentLevel)
  const selectedGift = computed(() => cartStore.cart?.selectedGift)

  return {
    giftList,
    showGiftLine,
    giftModal,
    nextLevel,
    currentLevelNumber,
    selectedGift,
  }
}
