import type { SalePoint } from '~/types/merchant'

interface Params {
  deliveryZones?: boolean
  salePoints?: MaybeRefOrGetter<SalePoint[] | null | undefined>
  selectedSalePoint?: MaybeRefOrGetter<SalePoint | null | undefined>
  onMapClick?: (coords: number[]) => Promise<unknown>
  onSalePointClick?: (salePoint: SalePoint) => void
}

export function useMap(
  mapContainerRef: Ref<HTMLElement | null>,
  params: Params = {},
) {
  const mapReady = ref(false)
  const mapInstance = shallowRef<ymaps.Map | null>(null)
  const deliveryZones = shallowRef<ymaps.GeoQueryResult>()
  const salePointsPlacemarks = shallowRef<ymaps.GeoQueryResult | null>()
  const cityBounds = ref<number[][] | null>()

  whenever(mapContainerRef, (mapContainer) => {
    mapReady.value = false
    const { onLoaded } = useYmapsScript()

    onLoaded(async (ymaps) => {
      await ymaps.ready()

      mapInstance.value = createMap(mapContainer)

      mapInstance.value.events.add('click', (event) => {
        const coords: number[] = event.get('coords')
        params.onMapClick?.(coords)
      })

      if (params.deliveryZones) {
        try {
          deliveryZones.value = ymaps.geoQuery(await getDeliveryZones())
          deliveryZones.value.addToMap(mapInstance.value)
        } catch (error) {
          console.warn('Не удалось загрузить зоны доставки', error)
        }
      }

      await setBounds()
      setSalePoints()

      mapReady.value = true
    })
  })

  function createMap(el: HTMLElement | string) {
    return new window.ymaps.Map(
      el,
      {
        center: useAppStore().merchant.coordinates || [37.75, 55.73],
        zoom: 10,
        controls: [],
      },
      { suppressMapOpenBlock: true, yandexMapDisablePoiInteractivity: true },
    )
  }

  async function loadKml() {
    const merchantId = useAppStore().merchant.id
    const backetName = useRuntimeConfig().public.S3_BACKET_NAME

    const kmlUrl = `https://storage.yandexcloud.net/${backetName}/maps/merchant_${merchantId}.kml`

    const kmlContent = await $fetch<string>(kmlUrl, {
      responseType: 'text',
      cache: 'no-cache',
    })

    const { kml } = await import('@tmcw/togeojson')

    const featureCollection = kml(
      new DOMParser().parseFromString(kmlContent, 'text/xml'),
    )

    return featureCollection.features
  }

  async function getDeliveryZones() {
    const features = await loadKml()

    return features
      .map((feature) => {
        if (
          feature.geometry &&
          feature.geometry.type === 'Polygon' &&
          feature.properties?.disabled != '1'
        ) {
          return new window.ymaps.GeoObject(
            {
              geometry: feature.geometry,
            },
            {
              ...feature.properties,
              interactivityModel: 'default#silent',
            },
          )
        }
      })
      .filter((item): item is ymaps.GeoObject => Boolean(item))
  }

  async function setDeliveryZonesBounds() {
    const bounds = deliveryZones.value?.getBounds()
    if (bounds) {
      mapInstance.value?.setBounds(bounds)
    }
  }

  async function setCityBounds() {
    const bounds = await getCityBounds()

    if (bounds) {
      mapInstance.value?.setBounds(bounds)
      mapInstance.value?.setZoom(10)
    }
  }

  async function getCityBounds() {
    if (cityBounds.value) {
      return cityBounds.value
    }

    const city = useAppStore().merchant.city
    if (!city) return

    const res = await window.ymaps.geocode(city, { results: 1 })

    const bounds = res.geoObjects.get(0).geometry?.getBounds()

    if (bounds) {
      cityBounds.value = bounds
    }

    return bounds
  }

  async function setBounds() {
    if (useAppStore().merchant.deliveryZonesEnabled) {
      setDeliveryZonesBounds()
    } else {
      await setCityBounds()
    }
  }

  function searchDeliveryZone(coords: number[]) {
    const deliveryZone = deliveryZones.value?.searchContaining(coords).get(0)

    if (deliveryZone) {
      const options = deliveryZone.options.getAll() as Record<string, string>
      return options.name
    }
  }

  watch(() => toValue(params.salePoints), setSalePoints)

  async function setSalePoints() {
    await until(mapInstance).toBeTruthy()
    const map = mapInstance.value as ymaps.Map

    if (salePointsPlacemarks.value) {
      salePointsPlacemarks.value.removeFromMap(map)
      salePointsPlacemarks.value = null
    }

    const salePoints = toValue(params.salePoints)

    if (!salePoints) return

    const placemarks = salePoints
      ?.filter(
        (
          point,
        ): point is SalePoint & {
          coordinates: NonNullable<SalePoint['coordinates']>
        } => Boolean(point.coordinates),
      )
      .map((point) => {
        return createSalePointPlacemark(point)
      })

    salePointsPlacemarks.value = window.ymaps.geoQuery(placemarks).addToMap(map)
  }

  function createSalePointPlacemark(
    point: SalePoint & { coordinates: NonNullable<SalePoint['coordinates']> },
  ) {
    const placemark = new window.ymaps.Placemark(
      [point.coordinates.longitude, point.coordinates.latitude],
      {
        id: point.id,
        iconCaption: point.name,
      },
      {
        preset:
          toValue(params.selectedSalePoint)?.id === point.id
            ? 'islands#dotIcon'
            : 'islands#icon',
        iconColor: useNuxtApp().$primaryColor.value,
      },
    )

    placemark.events.add('click', () => {
      params.onSalePointClick?.(point)
    })

    return placemark
  }

  watch(
    () => toValue(params.selectedSalePoint),
    async (value) => {
      if (salePointsPlacemarks.value) {
        /* @ts-expect-error https://yandex.ru/dev/jsapi-v2-1/doc/ru/v2-1/ref/reference/GeoQueryResult#setOptions */
        salePointsPlacemarks.value.setOptions('preset', 'islands#icon')

        if (!value) return

        // prettier-ignore
        // @ts-expect-error https://yandex.ru/dev/jsapi-v2-1/doc/ru/v2-1/ref/reference/GeoQueryResult#search prettier-ignore
        const object = salePointsPlacemarks.value.search(`properties.id = ${value.id}`)
          .get(0)

        if (object) {
          ;(object.options as ymaps.option.Manager).set(
            'preset',
            'islands#dotIcon',
          )
        }
      }
    },
  )

  return {
    mapReady,
    mapInstance,
    deliveryZones,
    searchDeliveryZone,
    setDeliveryZonesBounds,
    setCityBounds,
    setBounds,
  }
}
