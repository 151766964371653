export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false)

  const token = useCookie<string | null>('authToken', {
    default: () => null,
    maxAge: 31536000,
  })

  function logout() {
    token.value = null
    isAuthenticated.value = false

    const customerStore = useCustomerStore()
    customerStore.profile = null
    customerStore.bonuses = null
  }

  const authModal = ref(false)

  return {
    token,
    isAuthenticated,
    authModal,
    logout,
  }
})
