import { klona } from 'klona'
import type { Suggest } from '~/types/api'
import type { CustomerAddress, CustomerAddressList } from '~/types/customer'
import type { DeliveryZone, SalePoint } from '~/types/merchant'
import type { DeliveryAddress, OrderTypeCode } from '~/types/order'

export function useAddressSelect(
  mapContainerRef: Ref<HTMLElement | null>,
  onConfirmed: () => void,
) {
  const appStore = useAppStore()
  const orderStore = useOrderStore()
  const { geocode, geocoding } = useGeocode()

  const confirming = ref(false)
  const orderType = ref<OrderTypeCode>()
  const pickupPointId = ref<SalePoint['id'] | null>()
  const dineinPointId = ref<SalePoint['id'] | null>()
  const addressForm = ref<DeliveryAddress>({})
  const deliveryZone = ref<DeliveryZone | null>()
  const needSaveAddress = ref(false)
  const addressList = ref<CustomerAddressList | null>()
  const selectedCustomerAddress = ref<CustomerAddress | null>()

  const salePoints = computed(() => {
    if (orderType.value === 'pickup') {
      return appStore.merchant.salePoints
    }

    if (orderType.value === 'dinein') {
      return appStore.dineinPoints
    }

    return null
  })

  const selectedSalePoint = computed(() => {
    const id =
      orderType.value === 'dinein' ? dineinPointId.value : pickupPointId.value
    return salePoints.value?.find((point) => point.id == id)
  })

  const { mapReady, mapInstance, searchDeliveryZone, setBounds } = useMap(
    mapContainerRef,
    {
      deliveryZones: appStore.merchant.deliveryZonesEnabled,
      salePoints,
      selectedSalePoint,
      onMapClick,
      onSalePointClick,
    },
  )
  const markerCoords = ref<number[] | null>()
  useMapMarker(markerCoords, mapInstance)

  const merchantCity = computed(() => appStore.merchant.city)
  const addressIsValid = computed(() => {
    if (!addressForm.value.street) {
      return false
    }

    if (!addressForm.value.house) {
      return false
    }

    if (appStore.merchant.deliveryZonesEnabled && !deliveryZone.value) {
      return false
    }

    return true
  })
  const mapDisabled = computed(() => false)

  async function onAddressSuggestSelect(suggest: Suggest) {
    const geocodeResult = await geocode({ query: suggest.addressLine })
    markerCoords.value = geocodeResult.coords
    addressForm.value = {
      ...addressForm.value,
      ...geocodeResult,
    }
  }

  async function onMapClick(coords: number[]) {
    if (orderType.value !== 'delivery') return

    markerCoords.value = coords
    const geocodeResult = await geocode({ coords })
    addressForm.value = {
      ...addressForm.value,
      ...geocodeResult,
      coords: coords,
    }
  }

  function onSalePointClick(salePoint: SalePoint) {
    if (orderType.value === 'pickup') {
      pickupPointId.value = salePoint.id
    }

    if (orderType.value === 'dinein') {
      dineinPointId.value = salePoint.id
    }
  }

  async function confirmDelivery() {
    try {
      confirming.value = true
      orderStore.form.orderTypeCode = 'delivery'
      orderStore.form.address = {
        ...klona(addressForm.value),
        zone: deliveryZone.value?.name,
      }

      if (needSaveAddress.value) {
        await saveCustomerAddress()
      }
      onConfirmed()
    } catch (error) {
      handleError(error)
    } finally {
      confirming.value = false
    }
  }

  function confirmPickup() {
    confirming.value = true
    orderStore.form.orderTypeCode = 'pickup'
    orderStore.form.pickupPointId = pickupPointId.value
    confirming.value = false
    onConfirmed()
  }

  function confirmDinein() {
    confirming.value = true
    orderStore.form.orderTypeCode = 'dinein'
    orderStore.form.dineinPointId = dineinPointId.value
    confirming.value = false
    onConfirmed()
  }

  async function fetchAddressList() {
    try {
      addressList.value = await useNuxtApp().$api.getCustomerAddressList()
    } catch {
      addressList.value = []
    }
  }

  async function saveCustomerAddress() {
    await useNuxtApp().$api.saveCustomerAddress({
      ...addressForm.value,
      id: selectedCustomerAddress.value?.id,
      name: addressForm.value.addressLine,
    })
  }

  watch(
    markerCoords,
    async (coords) => {
      if (coords) {
        await until(mapReady).toBeTruthy()

        const name = searchDeliveryZone(coords)
        deliveryZone.value = appStore.merchant.deliveryZones.find(
          (zone) => zone.name == name,
        )
      } else {
        deliveryZone.value = null
      }
    },
    { immediate: true },
  )

  whenever(selectedCustomerAddress, (address) => {
    addressForm.value = klona(address)
    markerCoords.value = klona(address.coords)
  })

  watch(orderType, (orderType) => {
    if (orderType === 'delivery') {
      markerCoords.value = klona(addressForm.value.coords)
      if (!markerCoords.value) {
        until(mapReady).toBeTruthy().then(setBounds)
      }
    } else {
      markerCoords.value = null
      until(mapReady).toBeTruthy().then(setBounds)
    }
  })

  return {
    merchantCity,
    addressIsValid,
    confirming,
    geocoding,
    orderType,
    pickupPointId,
    dineinPointId,
    addressForm,
    deliveryZone,
    needSaveAddress,
    addressList,
    selectedCustomerAddress,
    mapReady,
    mapDisabled,
    confirmDelivery,
    confirmPickup,
    confirmDinein,
    saveCustomerAddress,
    fetchAddressList,
    onAddressSuggestSelect,
    onMapClick,
  }
}
