export default defineNuxtPlugin({
  setup() {
    const appStore = useAppStore()
    const orderStore = useOrderStore()
    const customerStore = useCustomerStore()
    const authStore = useAuthStore()
    const bus = useAppEventBus()
    // const authStore = useAuthStore()

    whenever(
      () => customerStore.profile,
      (profile) => {
        orderStore.form.contactName = profile.firstName
        orderStore.form.contactPhone = profile.phoneNumber
      },
      { immediate: true },
    )

    watchEffect(() => {
      if (appStore.merchant.asapOnly) {
        orderStore.form.asap = true
      }
    })

    whenever(
      () => appStore.merchantLoaded && !orderStore.selectedOrderType,
      () => {
        orderStore.form.orderTypeCode =
          appStore.merchant.orderTypes[0]?.code || 'delivery'
      },
      { immediate: true },
    )

    whenever(
      () =>
        appStore.merchantLoaded &&
        orderStore.form.pickupPointId &&
        !orderStore.selectedPickupPoint,
      () => {
        orderStore.form.pickupPointId = null
      },
      { immediate: true },
    )

    watch(
      () => orderStore.selectedOrderType,
      () => {
        if (import.meta.client) {
          useCartStore().fetchCart()
        }
      },
    )

    whenever(
      () => appStore.notWorkingHours,
      () => {
        orderStore.form.asap = false
      },
      { immediate: true },
    )

    bus.on('orderCreated', () => {
      orderStore.form.date = null
      orderStore.form.time = null
    })

    if (import.meta.client) {
      watch(
        () => authStore.isAuthenticated,
        () => {
          useCartStore().fetchCart()
        },
      )
    }
  },
})
