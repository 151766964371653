export function useMapMarker(
  coords: Ref<number[] | null | undefined>,
  map: Ref<ymaps.Map | null>,
) {
  const marker = shallowRef<ymaps.Placemark | null>(null)
  const zoom = 16

  watchEffect(() => {
    if (!map.value) return

    if (coords.value) {
      setMarker(coords.value)
    } else {
      removeMarker()
    }
  })

  function setMarker(coords: number[]) {
    if (!map.value) return

    if (!marker.value) {
      marker.value = createMarker(coords)
      map.value.geoObjects.add(marker.value)
    } else {
      marker.value.geometry?.setCoordinates(coords)
      if (map.value.geoObjects.indexOf(marker.value) === -1) {
        map.value.geoObjects.add(marker.value)
      }
    }

    map.value.setCenter(coords, zoom, { duration: 500 })
  }

  function removeMarker() {
    if (marker.value) {
      map.value?.geoObjects.remove(marker.value)
    }

    marker.value = null
  }

  function createMarker(coords: number[]) {
    return new window.ymaps.Placemark(
      coords,
      {},
      {
        iconLayout: 'islands#icon',
        iconColor: useNuxtApp().$primaryColor.value,
      },
    )
  }
}
