import type { OrderDate, OrderForm } from '~/types/order'

export const useOrderStore = defineStore('order', () => {
  const appStore = useAppStore()

  const form = useCookie<OrderForm>('orderForm', {
    default: () => ({
      orderTypeCode: 'delivery',
      asap: true,
      persons: 0,
      address: {},
      utm: {},
    }),
    maxAge: 2678400,
  })

  const isDelivery = computed(() => form.value.orderTypeCode === 'delivery')
  const isPickup = computed(() => form.value.orderTypeCode === 'pickup')
  const isDinein = computed(() => form.value.orderTypeCode === 'dinein')

  const selectedOrderType = computed(() =>
    appStore.merchant.orderTypes?.find(
      (type) => type.code == form.value.orderTypeCode,
    ),
  )

  const selectedPickupPoint = computed(() =>
    appStore.merchant.salePoints?.find(
      (point) => point.id === form.value.pickupPointId,
    ),
  )

  const selectedDineinPoint = computed(() =>
    appStore.dineinPoints?.find(
      (point) => point.id === form.value.dineinPointId,
    ),
  )

  const dateList = ref<OrderDate[]>([])

  async function fetchDateList() {
    if (appStore.merchant.asapOnly) {
      dateList.value = []
    } else {
      dateList.value = await useNuxtApp().$api.getOrderDateList()
    }
  }

  const selectedDate = computed(() =>
    dateList.value.find((item) => item.value == form.value.date),
  )

  const deliveryAddressIsValid = computed<boolean>(() => {
    if (!form.value.address.street) {
      return false
    }

    if (!form.value.address.house) {
      return false
    }

    if (appStore.merchant.deliveryZonesEnabled && !form.value.address.zone) {
      return false
    }

    return true
  })

  const orderDateTimeIsValid = computed<boolean>(() =>
    Boolean(selectedDate.value?.value && form.value.time),
  )

  const addressModal = ref(false)
  const addressOverlay = ref(false)

  const canNavigateToCheckout = computed(() => {
    if (isDelivery.value && !deliveryAddressIsValid.value) {
      return false
    }

    if (isPickup.value && !selectedPickupPoint.value) {
      return false
    }

    if (isDinein.value && !selectedDineinPoint.value) {
      return false
    }

    return true
  })

  const deliveryAddressSubtitle = computed(() => {
    const orderFields = appStore.merchant.orderFields
    const address = form.value.address

    return [
      [orderFields.building.label, address.building],
      [orderFields.entrance.label, address.entrance],
      [orderFields.doorphone.label, address.doorphone],
      [orderFields.floor.label, address.floor],
      [orderFields.flat.label, address.flat],
    ]
      .filter(([label, value]) => isNotEmpty(label && value))
      .map(([label, value]) => [label?.toLowerCase(), value].join(' '))
      .join(', ')
  })

  return {
    dateList,
    form,
    isDelivery,
    isPickup,
    isDinein,
    selectedOrderType,
    selectedPickupPoint,
    selectedDineinPoint,
    selectedDate,
    deliveryAddressIsValid,
    orderDateTimeIsValid,
    addressModal,
    addressOverlay,
    canNavigateToCheckout,
    deliveryAddressSubtitle,
    fetchDateList,
  }
})
