import chroma from 'chroma-js'

export default defineNuxtPlugin(() => {
  const appStore = useAppStore()
  const device = useDevice()

  if (!appStore.merchantLoaded) return

  const theme = computed(
    () => appStore.merchant.theme[device.isDesktop ? 'desktop' : 'mobile'],
  )

  const primaryColor = computed<string>(() =>
    chroma.valid(theme.value.primaryColor)
      ? chroma(theme.value.primaryColor).hex()
      : '#5DB631',
  )

  const cssVars = computed(() => {
    const borderRadiusRatio = theme.value.borderRadiusRatio

    const values: Record<string, string> = {
      '--theme-font-family': `"${theme.value.fontFamily}"`,
      '--theme-color-primary': primaryColor.value,
      '--theme-color-primary-rgb': chroma(primaryColor.value).rgb().join(' '),

      '--border-radius-sm': `${6 * borderRadiusRatio}px`,
      '--border-radius': `${12 * borderRadiusRatio}px`,
      '--border-radius-md': `${18 * borderRadiusRatio}px`,
      '--border-radius-lg': `${24 * borderRadiusRatio}px`,
      '--border-radius-xl': `${36 * borderRadiusRatio}px`,
      '--border-radius-2xl': `${48 * borderRadiusRatio}px`,
      '--border-radius-3xl': `${72 * borderRadiusRatio}px`,
    }

    return values
  })

  const css = computed(() => {
    const props = Object.entries(cssVars.value)
      .map(([key, value]) => `${key}:${value}`)
      .join(';')

    return `:root{${props}}`
  })

  useHead(() => ({
    htmlAttrs: {
      class: theme.value.dark ? 'dark' : 'light',
    },
    bodyAttrs: {
      style: {
        ...(theme.value.background.image && {
          'background-image': `url(${theme.value.background.image})`,
          'background-attachment': theme.value.background.fixed
            ? 'fixed'
            : 'inherit',
        }),
      },
    },
    style: [{ innerHTML: css.value }],
    link: [
      {
        rel: 'preconnect',
        href: 'https://fonts.googleapis.com',
      },
      {
        rel: 'stylesheet',
        href: `https://fonts.googleapis.com/css2?family=${encodeURI(theme.value.fontFamily)}:wght@400;700&display=swap`,
        crossorigin: '',
      },
    ],
  }))

  return {
    provide: {
      primaryColor,
    },
  }
})
