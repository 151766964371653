import type {
  Cart,
  CartRecommendations,
  GiftList,
  ItemsCountById,
} from '~/types/cart'
import type { Product } from '~/types/catalog'

export const useCartStore = defineStore('cart', () => {
  const orderStore = useOrderStore()
  const route = useRoute()
  const device = useDevice()

  const cart = ref<Cart | null>()
  const cartPending = ref(false)
  const cartReady = ref(false)

  const giftList = ref<GiftList | null>(null)
  const recommendations = ref<CartRecommendations | null>(null)
  const giftModal = ref(false)

  const itemsCountById = computed<ItemsCountById>(() => {
    if (!cart.value) return {}
    return cart.value?.items.reduce((result, item) => {
      result[item.id] = item.quantity + (result[item.id] || 0)
      return result
    }, {} as ItemsCountById)
  })

  const itemsCount = computed<number>(() => {
    return (
      cart.value?.items.reduce((sum, item) => {
        sum += item.quantity
        return sum
      }, 0) || 0
    )
  })

  async function fetchCart() {
    cartPending.value = true
    cart.value = await useNuxtApp().$api.getCart()
    cartPending.value = false
    cartReady.value = true
    Promise.all([fetchGiftList(), fetchRecommendations()])
  }

  async function fetchGiftList() {
    giftList.value = await useNuxtApp().$api.getGiftList()
  }

  async function fetchRecommendations() {
    recommendations.value = await useNuxtApp().$api.getCartRecommendations()
  }

  function getCartItem(productId: Product['id']) {
    return cart.value?.items.find((item) => item.id == productId)
  }

  const showFloatButton = computed(() => {
    return Boolean(
      cart.value &&
        !device.isDesktop &&
        !orderStore.addressOverlay &&
        (route.name === 'home' || route.path.includes('catalog')),
    )
  })

  return {
    cart,
    cartPending,
    cartReady,
    itemsCount,
    itemsCountById,
    giftList,
    recommendations,
    giftModal,
    showFloatButton,
    fetchCart,
    fetchGiftList,
    getCartItem,
  }
})
