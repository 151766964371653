<template>
  <input
    v-model="model"
    type="checkbox"
    class="h-5 w-5 rounded border-gray-300 text-primary transition focus:border-primary focus:outline-none focus:ring-4 focus:ring-primary/30 focus:ring-offset-0 dark:border-gray-800 dark:bg-gray-950"
  />
</template>

<script setup lang="ts">
const model = defineModel<boolean>()
</script>
