import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import type { Merchant, MerchantSettings, SalePoint } from '~/types/merchant'

export const useAppStore = defineStore('app', () => {
  const device = useDevice()

  const devicePlatform = computed<string>(() => {
    const isCordovaApp = useCordovaStore().isCordovaApp
    if (device.isDesktop) return 'desktop'
    if (device.isAndroid) return isCordovaApp ? 'android' : 'android_mobile'
    if (device.isIos) return isCordovaApp ? 'ios' : 'ios_mobile'
    return isCordovaApp ? 'app' : 'mobile'
  })

  const deviceId = useCookie('deviceId', { default: uuidv4, maxAge: 2678400 })
  const cookieMerchantKey = useCookie('merchantKey', { maxAge: 2678400 })

  const merchant = ref<Merchant>({} as Merchant)
  const merchantSettings = ref<MerchantSettings | null>()
  const merchantLoaded = ref(false)

  async function fetchMerchant(domain: string) {
    const { $api } = useNuxtApp()

    if (cookieMerchantKey.value) {
      const branches = await $api.getBranchesByDomain(domain)

      const branch = branches.find(
        ({ merchantKey }) => merchantKey == cookieMerchantKey.value,
      )

      if (branch) {
        merchant.value = await $api.getMerchantByKey(branch.merchantKey)
        return
      } else {
        cookieMerchantKey.value = null
      }
    }

    merchant.value = await $api.getMerchantByDomain(domain)
  }

  async function fetchMerchantSettings() {
    if (merchantSettings.value) return
    merchantSettings.value = await useNuxtApp().$api.getMerchantSettings()
  }

  const weekDay = dayjs().locale('en').format('dddd')

  const workingHoursToday = computed(() => {
    return merchant.value.workingHours.find(
      (item) => item.day.toLowerCase() == weekDay.toLowerCase(),
    )
  })

  const notWorkingHours = computed(() =>
    Boolean(merchantSettings.value?.statuses.notWorkingHours.active),
  )

  const dineinPoints = computed(() =>
    merchant.value.salePoints.filter(
      (point): point is SalePoint & { dinein: true } => point.dinein,
    ),
  )

  const pages = computed(() => {
    return [
      {
        name: 'Акции',
        to: '/actions',
      },
      ...merchant.value.customPages.map((item) => ({
        name: item.name,
        to: `/info/${item.id}`,
      })),
      {
        name: 'Статьи',
        to: '/articles',
      },
    ]
  })

  return {
    deviceId,
    devicePlatform,
    cookieMerchantKey,
    merchant,
    merchantSettings,
    merchantLoaded,
    workingHoursToday,
    notWorkingHours,
    dineinPoints,
    pages,
    fetchMerchant,
    fetchMerchantSettings,
  }
})
