import mitt from 'mitt'
import type { Events } from '~/types/bus'

export default defineNuxtPlugin({
  setup() {
    const emitter = mitt<Events>()

    return {
      provide: {
        bus: emitter,
      },
    }
  },
})
